.modal-container {
	width: 100vw;
	width: 100dvw;
	height: 100vh;
	height: 100dvh;
	flex: 1;
	display: flex;
}
.modal-content {
	max-width: 100vw;
	max-width: 100dvw;
	max-height: 100vh;
	max-height: 100dvh;
}
.modal-touchable {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	width: 100dvw;
	height: 100vh;
	height: 100dvh;
}
